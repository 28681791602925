import { SupportedLocale, TranslationString } from '@/models/Language';
import i18n from '@/plugins/i18n';
import PianoImage from '@/assets/piano-thumbnail.png';
import GuitarImage from '@/assets/guitar-thumbnail.png';
// TODO: replace drums image
import DrumsImage from '@/assets/drums-thumbnail.png';
import { PlanCategory } from '@/models/PlanCategory';

export enum InstrumentKey {
	PIANO = 'piano',
	GUITAR = 'guitar',
	DRUMS = 'drums',
}

export interface Instrument {
	ID: InstrumentKey,
	name: string,
	displayName: TranslationString,
	image: string,
	planCategory: PlanCategory;
	language: Readonly<SupportedLocale[]>,
}

export type InstrumentDictionary = Record<InstrumentKey, Instrument>;

export const instruments: InstrumentDictionary = {
	[InstrumentKey.PIANO]: {
		ID: InstrumentKey.PIANO,
		name: 'Piano',
		displayName: () => i18n.global.t('instrument.piano'),
		image: PianoImage,
		planCategory: 'piano',
		language: ['de', 'en'],
	},
	[InstrumentKey.GUITAR]: {
		ID: InstrumentKey.GUITAR,
		name: 'Guitar',
		displayName: () => i18n.global.t('instrument.guitar'),
		image: GuitarImage,
		planCategory: 'guitar',
		language: ['de', 'en', 'es'],
	},
	[InstrumentKey.DRUMS]: {
		ID: InstrumentKey.DRUMS,
		name: 'Drums',
		displayName: () => i18n.global.t('instrument.drums'),
		image: DrumsImage,
		planCategory: 'drums',
		language: ['de'],
	},
} as const;

export const getInstrumentsForLanguage = (language: SupportedLocale): Instrument[] => Object.values(instruments).filter((instrument) => instrument.language.includes(language));
